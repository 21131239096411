import React, { Component } from "react";

import { Link } from "react-router-dom";

export class Rutest extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/react"> Back </Link>
        <h1>React testing</h1>
        <h2>Types of testing</h2>
        <ol>
          <li>
            <b>Unit testing </b> Test individual pieces of code (smallest units)
            in isolation. These units could be a React component, a function, or
            a hook.
          </li>
          <li>
            <b>integration tests</b> Test how multiple components work together
            or how a component interacts with external logic (e.g., API calls,
            context, Redux store).
          </li>
          <li>
            <b>End-to-End (E2E) Tests </b> Test the entire application from
            start to finish, simulating real user behavior. This typically
            includes interactions across multiple pages or features.
          </li>
        </ol>
        <h2>Tools</h2>
        <p>
          When creating a react app with the npx create-react-ap there are 2
          testing libraries preinstalled in the Package
        </p>
        <ol>
          <li>
            <b>Jest: </b> for running the tests and validating the results
          </li>
          <li>
            <b>React testing library: </b> for simulating the rendering of the
            react app.
          </li>
        </ol>
        <p>
          A new react app comes already with a basic testing script found in
          App.test.js
        </p>
        <p>
          * The naming convention for the test scripts files is
          "ComponentName.test.js" and should be placed next to the component you
          want to test
        </p>
        <p>
          Take a moment to examine App.test.js and to run the test use the
          command: "npm test" in the terminal
        </p>
        <img src="./images/test01.png" alt="" />
        <h2>Example 1</h2>
        <b>Writing our first test:</b> a test script could consist of 3 parts:
        <ol>
          <li>Unit setup</li>
          <li>Interaction logic</li>
          <li>Validating results</li>
        </ol>
        <img src="./images/test02.png" alt="" />
        <h2>Example 2: testing suite with user events</h2>
        <p>
          To create a suite of several tests you can group your tests using the
          describe method, which also takes 2 attributes, a description and an
          anonymous fucntion
        </p>
        <p>
          In this example we'll run several tests for different scenarios
          including a user event to click on a button
        </p>
        <p>
          <b>Important! </b>To be able to test the user interaction in async
          mode please make sure to update the user-event library to above v14{" "}
        </p>
        <b>npm install @testing-library/user-event@latest</b>
        <p>
          <b>Component to test:</b>
        </p>
        <img src="./images/test03.png" alt="" />
        <b>test script</b>
        <p>
          notice that we used the method queryByText instead of get in the
          scenario where we want to check if an element doesn't display. The
          reason behind that is that the method getByText will throw an error if
          the element is not found no matter what we intend to test.
        </p>
        <img src="./images/test04.png" alt="" />
        <hr />
        <p>reference: https://jestjs.io/</p>
        <p>
          reference:
          https://testing-library.com/docs/react-testing-library/intro/
        </p>
      </div>
    );
  }
}

export default Rutest;
